body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -1 !important;
  background-color: #191414 !important;
  overflow-x: hidden;
}

code {
  font-family: 'Inconsolata', monospace;
}

.logo {
  max-width: 95px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.spotify-green {
  background-color: #1DB954 !important;
}

.text-spotify {
  color: #ffffff !important;
}

.spotify-dark {
  background-color: #191414 !important;
}

.spotify {
  cursor: pointer !important;
  text-decoration: none;
  background-color: #1DB954 !important;
  border-radius: 24px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 240px !important;
  color: #ffffff !important;
  font-size: 12px;
  line-height: 1;
  border-radius: 500px;
  padding: 11px 32px 9px;
}

.twitterHandle {
  text-decoration: none;
  text-decoration-style: none;
  color: #ffffff;
}

.twitterHandle:hover {
  color: rgb(0, 136, 94);
}

.border-secondary {
  border-color: rgb(0, 136, 94) !important;
}

.btn-secondary {
  color: #fff;
  background-color: rgb(0, 136, 94) !important;
}

.text-secondary {
  color: rgb(0, 136, 94) !important;
}